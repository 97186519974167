.control {
    --background: var(--form_background, var(--color-white));
    --color: var(--form_color, var(--color-black));
    --focus-color: var(--form_focus-color, var(--color-black));
    --border-color: var(--form_border-color, var(--color-white-darken-5));
    --label-color: var(--form_label-color, var(--color-black));
    --arrow-color: var(--color);
    --border-radius: var(--form_border-radius, var(--global-border-radius));
    width: 100%;
    margin: var(--gap) auto calc(3 * var(--gap));
    position: relative;

    &:focus-within {

        .label {
            color: hsl(var(--label-color));
        }
    }
}

.wrapper {
    width: 100%;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    background-color: hsl(var(--background));
    border-radius: var(--border-radius);
    border: 1px solid hsla(var(--border-color), 0.5);

    &:focus,
    &:hover {

        &::after {
            background-color: hsla(var(--arrow-color), 0.75);
        }
    }

    &::after {
        width: 0.8em;
        height: 0.5em;
        margin-right: 1em;
        grid-area: select;
        justify-self: end;
        content: '';
        background-color: hsla(var(--arrow-color), 0.5);
        transition: background-color var(--transition-out) var(--ease-out-back);
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.select {
    width: 100%;
    margin: 0;
    padding: calc(0.75 * var(--gap)) calc(1em + var(--gap)) calc(0.75 * var(--gap)) var(--gap);
    grid-area: select;
    position: relative;
    z-index: 1;
    appearance: none;
    cursor: inherit;
    background-color: transparent;
    color: hsl(var(--color));
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    border: none;

    &:not([disabled]):focus {

        + .outline {
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -2px;
            left: -1px;
            z-index: 0;
            box-shadow: 0 0 0 2px hsla(var(--focus-color), 0.25), 0 0 0 3px hsla(var(--color-white), 0.25);
            border-radius: inherit;
        }
    }
}

.label {
    width: 100%;
    padding: calc(0.25 * var(--gap)) var(--gap);
    display: flex;
    font-weight: 700;
    transition: color var(--transition-in) var(--ease-out-back);
}

.option {
    background: hsl(var(--background));
}

.required {
    position: absolute;
    top: calc(0.6 * var(--gap));
    left: calc(0.4 * var(--gap));
    color: hsl(var(--global_danger-color, var(--color-red)));
    font-size: var(--font-size-00);
}

.success {
    --variant: var(--global_success-color, var(--color-green));
}

.info {
    --variant: var(--global_info-color, var(--color-blue));
}

.warning {
    --variant: var(--global_warning-color, var(--color-yellow));
}

.danger {
    --variant: var(--global_danger-color, var(--color-red));
}

.success,
.info,
.warning,
.danger {

    &,
    &:focus,
    &:hover,
    .wrapper:focus,
    .wrapper:hover {
        --focus-color: var(--variant);
        --arrow-color: var(--variant);
        --border-color: var(--variant);
        --label-color: var(--variant);
    }
}
